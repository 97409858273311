import React, { useState, useEffect } from 'react';
import http from '../../tools/http.js'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import styles from '../style.module.css';
import { toast } from 'react-toastify';

function UpdateList(props) {

  const done = () => toast.success("تمت العملية بنجاح!");
  const retry = () => toast.error("قم بإعادة المحاولة وفي حال التكرار تواصل مع الإدارة", {
    autoClose: 3000,
  });
  const [formData, setFormData] = useState({
    name: '',

  });
  const [formErrors, setFormErrors] = useState({});
  const errors = {};
  const validateForm = () => {
    let isValid = true;
    if (!formData.name.trim()) {

      errors.name = 'يجب إدخال name';
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const handelsend = async (e) => {
    e.preventDefault();
    validateForm();
    if (Object.keys(formErrors).length === 0) {
      const requestData = {
        id: props.data.id,
        name_table:props.name,
        ...formData,
        token: 'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11',
      };

      try {
        const response = await http.post(`/updatelist`, requestData)
        props.get(0);
        done()
        hideForm(e);
        // يمكنك أيضًا إضافة أية أوامر إضافية بعد نجاح الإرسال هنا
      } catch (error) {
        retry()
        //console.error('حدث خطأ أثناء الطلب:', error);
      }
    }
  };
  const handleInputChange = (e) => {
    // تحديث حالة النموذج عند تغيير الإدخال
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const hideForm = (e) => {
    e.preventDefault();
    setFormData({
      name: '',

    });
    // document.querySelector('.membrane').classList.remove('showmembrane');
    // document.querySelector('.membrane').classList.add('hiddenmembrane');
    document.querySelector(`.${styles.UpdateComponent}`).classList.remove(styles.showform);
    document.querySelector(`.${styles.UpdateComponent}`).classList.add(styles.hiddenform);
  };

  return (
    <div className={styles.UpdateComponent}>
      <form onSubmit={handelsend}>

        <TextField
          id="name"
          name="name"
          onChange={handleInputChange}
          helpertext={formErrors.name}
          label={props.label}
          type='text'
          variant="filled"
          error={formErrors.name}
        />

        <div className={styles.buttons}>
          <input type='submit' value={'ارسال'} />
          <input type='button' value={'الغاء'} onClick={hideForm} />
        </div>
      </form>
    </div>
  );
}

export default UpdateList;