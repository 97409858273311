import http from '../tools/http';
import { toast } from 'react-toastify';

function useDeleteData(RouterDelete, initialState,get) {
    const done = () => toast.success("تمت العملية بنجاح!");
    const retry = (error) => toast.error(error.message||"قم بإعادة المحاولة وفي حال التكرار تواصل مع الإدارة", {
        autoClose: 3000,
    });
    const handleDelete = async () => {
        try {
             await http.post(RouterDelete, initialState).then(()=>{
                done()
                get(0)
             })
        } catch (error) {
            retry(error)
            console.error('حدث خطأ أثناء الحذف:', error);
        }
    };

    return { handleDelete };
}

export default useDeleteData;
