import React, { useState } from 'react';
import http from '../../tools/http';
import TextField from '@mui/material/TextField';
import styles from '../style.module.css';
import { toast } from 'react-toastify';
import SearchInput from '../../tools/SearchInput';

function Addquotations(props) {
  const [formData, setFormData] = useState({
    book_id:'',
    body: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const errors = {}
  const validateForm = () => {
    let isValid = true;
    if (!formData.book_id) {

      errors.book_id = 'يجب إدخال الكتاب';
      isValid = false;
    }
    if (!formData.body.trim()) {
      errors.body = 'يجب إدخال الإقتباس';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };
  const done = () => toast.success("تمت العملية بنجاح!");
  const retry = () => toast.error("قم بإعادة المحاولة وفي حال التكرار تواصل مع الإدارة", {
    autoClose: 3000,
  });

  const handelsend = async (e) => {
    e.preventDefault();
    validateForm();
    if (Object.keys(errors).length === 0) {
      const requestData = {
        ...formData,
      };

      try {
        const response = await http.post('/addquotations', requestData)
        props.get(0);
        done()
      } catch (error) {
        retry()
      }
    }
  };
  const handleInputChange = (e) => {
    // تحديث حالة النموذج عند تغيير الإدخال
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const hideForm = (e) => {
    e.preventDefault();
    setFormData({
      book_id: props.book_id,
      body: '',

    });
    document.querySelector(`.${styles.formcomponent}`).classList.remove(styles.showform);
    document.querySelector(`.${styles.formcomponent}`).classList.add(styles.hiddenform);
  };

  return (
    <div className={styles.formcomponent}>
      <form onSubmit={handelsend}>
      <SearchInput
          label='الكتب'
          name='books'
          nameField='book_id'
          value='id'
          routerGet='/getlistsPage'
          routerSearch='/searchlist'
          setFormData={setFormData}
        />
        <TextField
          name="body"
          onChange={handleInputChange}
          type='text'
        />
 
        <div className={styles.buttons}>
          <input type='submit' value={'ارسال'} />
          <input type='button' value={'الغاء'} onClick={hideForm} />
        </div>
      </form>
    </div>
  );
}

export default Addquotations;