import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { Suspense, lazy } from 'react';
import AuthManager from './tools/AuthManager.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainShow from './OnePage/MainShow';
import List from './Component/List/List';
// import InputSearch from './Tools/InputSearch';
// const Permissions = lazy(() => import('./Component/Permissions/Permissions'));
// const User_types = lazy(() => import('./Component/User_types/User_types'));
// const Permission_breakdown = lazy(() => import('./Component/Permission_breakdown/Permission_breakdown'));
const Users = lazy(() => import('./Component/User/User'));
const Book_classifications = lazy(() => import('./Component/Book_classifications/Book_classifications'));
const Books = lazy(() => import('./Component/Books/Books'));
const Book_downloads = lazy(() => import('./Component/Book_downloads/Book_downloads'));
const My_note = lazy(() => import('./Component/Notes/My_note'));
// const Library_branches = lazy(() => import('./Component/Library_branches/Library_branches'));
const Library_structure_types = lazy(() => import('./Component/Library_structure_types/Library_structure_types'));
const Library_structure = lazy(() => import('./Component/Library_structure/Library_structure'));
// const Branch_books = lazy(() => import('./Component/Branch_books/Branch_books'));
const Book_capy = lazy(() => import('./Component/book_capys/Book_capy'));
const User_comments = lazy(() => import('./Component/User_comments/User_comments'));
const Reviews = lazy(() => import('./Component/Reviews/Reviews'));
// // const Action = lazy(() => import('./Component/Action/Action'));
const Book_history = lazy(() => import('./Component/Book_history/Book_history.js'));
// const Notifications = lazy(() => import('./Component/Notifications/Notifications'));
const ShowBook = lazy(() => import('./OnePage/ShowBook.js'));
const Dashbord = lazy(() => import('./Dashbord/Dashbord'));
const Login = lazy(() => import('./Dashbord/Login'));
const Logout = lazy(() => import('./Dashbord/Logout'));
const User_favorites = lazy(() => import('./Component/UserFavorites/User_favorites'));
const PdfViewer = lazy(() => import('./OnePage/Viewer'));
const NotFound = lazy(() => import('./OnePage/NotFound'));

function App() {

  return (


    <Router>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        limit={5}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {AuthManager.isAuthenticated ? <Dashbord /> : <Login />}
      <Routes>
        {AuthManager.isAuthenticated ?
          <>
            <Route path="/" element={<Suspense fallback='loading...'><MainShow /></Suspense>} />
            <Route path="/Authors" element={<Suspense fallback='loading...'><List key="authors" name='authors' label='اسم المؤلف' /></Suspense>} />
            <Route path="/translators" element={<Suspense fallback='loading...'><List key="translators" name='translators' label='اسم المترجم' /></Suspense>} />
            <Route path="/Publishers" element={<Suspense fallback='loading...'><List key="publishers" name='publishers' label='دار النشر' /></Suspense>} />
            <Route path="/Book_types" element={<Suspense fallback='loading...'><List key="book_types" name='book_types' label='نوع الكتاب' /></Suspense>} />
            <Route path="/language_book" element={<Suspense fallback='loading...'><List key="language_book" name='language_book' label='لغة الكتاب' /></Suspense>} />
            <Route path="/Books" element={<Suspense fallback='loading...'><Books /></Suspense>} />
            <Route path="/Book_downloads" element={<Suspense fallback='loading...'><Book_downloads /></Suspense>} />
            <Route path="/User_comments" element={<Suspense fallback='loading...'><User_comments /></Suspense>} />
            <Route path="/Reviews" element={<Suspense fallback='loading...'><Reviews /></Suspense>} />
            <Route path="/My_note" element={<Suspense fallback='loading...'><My_note /></Suspense>} />
            <Route path="/Users" element={<Suspense fallback='loading...'><Users /></Suspense>} />
            <Route path="/Book_classifications" element={<Suspense fallback='loading...'><Book_classifications /></Suspense>} />
            <Route path="/book_capy" element={<Suspense fallback='loading...'><Book_capy /></Suspense>} />
            <Route path="/Library_structure_types" element={<Suspense fallback='loading...'><Library_structure_types /></Suspense>} />
            <Route path="/Library_structure" element={<Suspense fallback='loading...'><Library_structure /></Suspense>} />
            <Route path="/Book_history" element={<Suspense fallback='loading...'><Book_history /></Suspense>} />
            {/*<Route path="/Permissions" element={<Suspense fallback='loading...'><Permissions /></Suspense>} />
            <Route path="/User_types" element={<Suspense fallback='loading...'><User_types /></Suspense>} />
            <Route path="/Permission_breakdown" element={<Suspense fallback='loading...'><Permission_breakdown /></Suspense>} />
            <Route path="/Book_downloads" element={<Suspense fallback='loading...'><Book_downloads /></Suspense>} />
            <Route path="/Library_branches" element={<Suspense fallback='loading...'><Library_branches /></Suspense>} />
        
            <Route path="/Branch_books" element={<Suspense fallback='loading...'><Branch_books /></Suspense>} />
            <Route path="/Notifications" element={<Suspense fallback='loading...'><Notifications /></Suspense>} />
            <Route path="/ShowBook/:id" element={<Suspense fallback='loading...'><ShowBook /></Suspense>} />
            <Route path="/Login" element={<Suspense fallback='loading...'><Login /></Suspense>} />
            */}
            <Route path="/ShowBook/:id/:test" element={<Suspense fallback='loading...'><ShowBook /></Suspense>} />
            <Route path="/ShowBook/:id" element={<Suspense fallback='loading...'><ShowBook /></Suspense>} />
            <Route path='/complate_read/:pdfUrl' element={<Suspense fallback='loading...'><PdfViewer /></Suspense>} />
            <Route path="/User_favorites" element={<Suspense fallback='loading...'><User_favorites /></Suspense>} />
            <Route path="/logout" element={<Suspense fallback='loading...'><Logout /></Suspense>} />

          </> :
          <Route path="*" element={<Suspense fallback='loading...'><Login /></Suspense>} />}
      </Routes>

    </Router>

  );
}

export default App;
