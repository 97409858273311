import axios from 'axios';
import Cookies from 'js-cookie';
   const baseURL = 'https://backlib.sham-dev.com/';
  // const baseURL = 'http://localhost:5555/';

  var user=Cookies.get('userData')?JSON.parse(Cookies.get('userData')):{token:null,isAuthenticated:false}
  const http = axios.create({
    baseURL,
    timeout: 20000,
    headers: {
      'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
      token: user.token
    },
  });
  
  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 403) {
        Cookies.remove('userData')
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
  export { baseURL };
  export default http;
