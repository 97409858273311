import styles from '../style.module.css';
import ContentQuotations from './ContentQuotations';

function My_note() {

    return (
        <div className={styles.container}>
        <br/>
            <ContentQuotations />
        </div>
    );
}

export default My_note;