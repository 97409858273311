import React, { useEffect, useState } from 'react'
import MainBook from "./MainBook"
import Quotations from "../Component/Quotations/Quotations"
import styles from './MainShow.module.css';

function MainShow() {
  const [choseBookOrQuotations, SetchoseBookOrQuotations] = useState('Book');
  useEffect(() => {
  }, [choseBookOrQuotations])
  return (
    <div className={styles.mainShow}>
      <div className={styles.choseBookOrQuotations}>
        <div onClick={() => { SetchoseBookOrQuotations("Book") }}>الكتب {choseBookOrQuotations === "Book" ? <div className={styles.point}></div> : ""}</div>
        <div onClick={() => { SetchoseBookOrQuotations("Quotations") }}>الإقتباسات {choseBookOrQuotations === "Quotations" ? <div className={styles.point}></div> : ""}</div>
      </div>
      {choseBookOrQuotations === "Book" ? <MainBook /> : <Quotations />}
    </div>
  )
}

export default MainShow