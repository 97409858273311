import Cookies from 'js-cookie';
 class  AuthManager {
    static user=Cookies.get('userData')?JSON.parse(Cookies.get('userData')):{token:null,isAuthenticated:false}
    static isAuthenticated=this.user.token?true:false;
    static token=this.user.token;
    static id=this.user.id;
    static user_type=this.user.user_type;

    static logout() {
        this.isAuthenticated = false;
        this.token = null;
        this.user_type = null;
    }
    
}

export default AuthManager;
