import React, { useCallback, useRef, useEffect, useState } from 'react';
import http from '../../tools/http.js'
import UpdateList from './UpdateList';
import AlertList from './AlertList';
import AddList from './AddList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../style.module.css';
import MarageList from './MarageList.js'
import MergeTypeIcon from '@mui/icons-material/MergeType';
function ContentList(props) {
    const [row, setRow] = useState({});
    const [test, setTest] = useState([]);
    const [page, setPage] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const lastItemRef = useRef(null);
    const containerRef = useRef(null);
    let timer;
    var get = false;

    const fetchData = async (pageNum) => {
        try {
            const requestData = {
                name_table: props.name,
                page: pageNum
            };
            const response = await http.post(`/getlistsPage`, requestData);
            if (pageNum === 0) {
                setTest(response.data);
            } else {
                setTest(prevTest => [...prevTest, ...response.data]);
            }
            setPage(pageNum + 1);
        } catch (error) {
            console.error('Error occurred while making the request:', error);
        }
    };
    useEffect(() => {
        fetchData(0);
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            if (lastItemRef.current) {
                const { top } = lastItemRef.current.getClientRects()[0]; // استخدم getClientRects()[0]
                const { clientHeight } = containerRef.current;
                if (clientHeight + 150 >= top && !get) {
                    get = true
                    fetchData(page);
                }
            }
        };
        const scrollElement = containerRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);

            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [page]);

    const HandelSearch = () => {
        clearTimeout(timer)
        const searchValue = document.getElementsByClassName(styles.search)[0].value.trim();
        if (searchValue.trim()) {
            timer = setTimeout(async () => {
                const requestData = {
                    name_table: props.name,
                    search: searchValue
                };
                const response = await http.post('/searchlist', requestData);
                setTest(response.data);
                setIsSearch(true);
            }, 1000);
        } else {
            setIsSearch(false)
            fetchData(0)
        }
    }
    const handelUpdate = (e, rowData) => {
        e.preventDefault();
        setRow(rowData);
        document.querySelector(`.${styles.UpdateComponent}`).classList.remove(styles.hiddenform);
        document.querySelector(`.${styles.UpdateComponent}`).classList.add(styles.showform);
    };
    const handelMarage = (e, rowData) => {
        e.preventDefault();
        setRow(rowData);
        document.getElementsByClassName(`${styles.marageComponent}`)[0].classList.remove(styles.hiddenform);
        document.getElementsByClassName(`${styles.marageComponent}`)[0].classList.add(styles.showform);
    };
    const showForm = (e) => {
        e.preventDefault();
        document.querySelector(`.${styles.formcomponent}`).classList.remove(styles.hiddenform);
        document.querySelector(`.${styles.formcomponent}`).classList.add(styles.showform);
    };

    const showRecord = (event, rowData) => {
        event.stopPropagation();
        setRow(rowData);
        document.querySelector(`.${styles.showRecord}`).classList.remove(styles.ishiddenRecord);
        document.querySelector(`.${styles.showRecord}`).classList.add(styles.isShowRecord);
    };

    return (
        <div className={styles.component}>
            <AddList get={fetchData} name={props.name} label={props.label} />
            <AlertList row={row} get={fetchData} name={props.name} />
            <UpdateList data={row} get={fetchData} name={props.name} label={props.label}/>
            <MarageList data={row} get={fetchData} name={props.name} label={props.label}/>
            <div className={styles.headcomponent}>
                <div className={styles.firstElment}>
                    <div className={styles.searchDiv} onMouseEnter={() => {
                        document.querySelector(`.${styles.search}`).classList.add(styles.searchActive);
                    }} onMouseLeave={() => {
                        document.querySelector(`.${styles.search}`).classList.remove(styles.searchActive);
                    }}>
                        <SearchIcon sx={{ fontSize: "40px" }} />
                        <input type='text' className={styles.search} onChange={HandelSearch} />
                    </div>
                </div>
                <div className={styles.add} onClick={showForm}> إضافة</div>
            </div>
            <div className={styles.content} ref={containerRef}>
                <table >
                    <thead>
                        <tr className={styles.contentdata}>
                            {test.length > 0 ? (
                                <>
                                    {/* {Object.keys(test[0]).map((key) => (
                                        <th key={key}>{key}</th>
                                    ))} */}
                                    <th>المعرف</th>
                                    <th>الاسم</th>
                                    <th>عمليات</th>
                                </>
                            ) : (
                                <th colSpan="2" style={{ textAlign: 'center' }}>لا يوجد بيانات</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {test.map((rowData, index) => (

                            <tr className={styles.contentdata} key={index} id={index} ref={(!isSearch && index === test.length - 1) ? lastItemRef : null}>
                                {Object.values(rowData).map((value, i) => (
                                    <td key={i}>{value}</td>
                                ))}
                                <td className={styles.actionList}>
                                    <EditIcon
                                        sx={{ color: 'green', cursor: "pointer" }}
                                        onClick={(event) => { handelUpdate(event, rowData) }}
                                    />

                                    <MergeTypeIcon
                                        sx={{ color: 'red', cursor: "pointer" }}
                                        onClick={(event) => { handelMarage(event, rowData) }}
                                    />
                                    {/* <DeleteIcon
                                        sx={{ color: 'red', cursor: "pointer" }}
                                        onClick={(event) => { showRecord(event, rowData, index) }}
                                    /> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={styles.footercomponent}>
            </div>
        </div>
    );
}

export default ContentList;
