import React from 'react';
import TextField from '@mui/material/TextField';
import styles from '../style.module.css';
import useForm from '../../MyHooks/UseForm';
function AddList(props) {

  const initialState = {
    name: '',
    name_table: props.name
  }
  const errors = {}
  const hideForm = () => {
    resetForm()
    document.querySelector(`.${styles.formcomponent}`).classList.remove(styles.showform);
    document.querySelector(`.${styles.formcomponent}`).classList.add(styles.hiddenform);
  };
  const validateForm = () => {
    let isValid = true;
    if (!formData.name.trim()) {
      errors.name = 'يجب إدخال name';
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };
  const { formData, formErrors, setFormErrors, handleInputChange, resetForm, submitForm } = useForm(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm('/addlist', props.get, validateForm);
  };

  return (
    <div className={styles.formcomponent}>
      <form onSubmit={handleSubmit}>
      <TextField
        id="name"
        name="name"
        onChange={handleInputChange}
        label={props.label}
        type='text'
        helpertext={formErrors.name}
        error={Boolean(formErrors.name)}
        variant="filled"
        style={{ direction: "rtl" }}
      />

        <div className={styles.buttons}>
          <input type='submit' value={'ارسال'} />
          <input type='button' value={'الغاء'} onClick={hideForm} />
        </div>
      </form>
    </div>
  );
}

export default AddList;