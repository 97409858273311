import React, { useState } from 'react';
import http from '../../tools/http.js'
import TextField from '@mui/material/TextField';
import styles from '../style.module.css';
import { toast } from 'react-toastify';
import SearchInput from '../../tools/SearchInput';


function MarageList(props) {

    const done = () => toast.success("تمت العملية بنجاح!");
    const retry = () => toast.error("قم بإعادة المحاولة وفي حال التكرار تواصل مع الإدارة", {
        autoClose: 3000,
    });
    const [formData, setFormData] = useState({
        name: '',

    });
    const [formErrors, setFormErrors] = useState({});
    const errors = {};
    const validateForm = () => {
        let isValid = true;
        if (!formData.name.trim()) {

            errors.name = 'يجب إدخال name';
            isValid = false;
        }
        setFormErrors(errors);
        return isValid;
    };

    const handelsend = async (e) => {
        e.preventDefault();
        validateForm();
        if (Object.keys(formErrors).length === 0) {
            const requestData = {
                id: props.data.id,
                name_table: props.name,
                ...formData,
                token: 'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11',
            };

            try {
                await http.post(`/maragelist`, requestData)
                props.get(0);
                done()
                hideForm(e);
            } catch (error) {
                retry()
            }
        }
    };
    const handleInputChange = (e) => {
        // تحديث حالة النموذج عند تغيير الإدخال
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: inputValue,
        }));
    };

    const hideForm = (e) => {
        e.preventDefault();
        setFormData({
            name: '',

        });
        document.getElementsByClassName(`${styles.marageComponent}`)[0].classList.remove(styles.showform);
        document.getElementsByClassName(`${styles.marageComponent}`)[0].classList.add(styles.hiddenform);
    };

    return (
        <div className={styles.marageComponent}>
      
            <form onSubmit={handelsend}>
            <p>
                سيتم تحويل كل الكتب من القيمة القديمة الى القيمة الجديدة
            </p>
                <SearchInput
                    label={props.label}
                    name={props.name}
                    nameField='name'
                    value='name'
                    routerGet='/getlistsPage'
                    routerSearch='/searchlist'
                    setFormData={setFormData}
                />
                {/* 
                <TextField
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    label={props.label}
                    helpertext={formErrors.name}
                    type='text'
                    variant="filled"
                    error={formErrors.name}
                /> */}

                <div className={styles.buttons}>
                    <input type='submit' value={'ارسال'} />
                    <input type='button' value={'الغاء'} onClick={hideForm} />
                </div>
            </form>
        </div>
    );
}

export default MarageList;