import { useCallback, useEffect,useId } from 'react';
import styles from '../style.module.css';
import ContentList from './ContentList';

function List(props) {
  return (
    <div className={styles.container}>
    <h1>{props.label}</h1>
      <ContentList name={props.name} label={props.label}/>
    </div>
  );
}

export default List;