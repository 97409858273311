import * as React from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';import http, { baseURL } from '../tools/http.js'
import { useNavigate } from 'react-router-dom';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import AuthManager from '../tools/AuthManager.js';
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function RecipeReviewCard(props) {
    const navigate = useNavigate();
    const handelDownload = async (e, rowData) => {
        e.preventDefault();
        const requestData = {
            id: rowData.id
        };
        try {
            const response = await http.post('/downloadbook', requestData, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${rowData.name}.pdf`); // تحديد اسم الملف
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            //console.error('Error downloading image: ', error);
        }

    }
    const handelfavorite = async (e, rowData) => {
        e.preventDefault();
        props.fetchDatasingle(rowData.id);
        const requestData = {
            user_id: AuthManager.id,
            book_id: rowData.id,
            date_added: new Date(),
        };
        try {
            await http.post('/adduser_favorites', requestData)
            props.fetchDatasingle(rowData.id);

        } catch (error) {
        }

    }

    return (
        <div className='card'>
            <LazyLoadImage
                src={`${baseURL}images/${props.image}`}
            />
            <div className='dataCard'>
                <p> {props.rowData.name}</p>
                {/* {props.classes ? <h5>{props.classes}</h5> : <></>} */}
                <Container fixed sx={{ height: '8vh', width: "100%", display: 'flex', position: 'relative' }}>
                    <CardActions disableSpacing>
                        <IconButton aria-label="add to favorites" style={{ color: props.rowData.is_favorite ? 'red' : 'rgb(182 179 179)' }} >
                            <FavoriteIcon onClick={(event) => { handelfavorite(event, props.rowData) }} />
                        </IconButton>
                        <IconButton aria-label="view"  >
                            <WysiwygIcon onClick={() => { navigate(`/showbook/${props.rowData.id}`) }} />
                        </IconButton>
                        {props.rowData.has_download ? <>

                            <IconButton aria-label="download" >
                                <DownloadIcon onClick={(e) => { handelDownload(e, props.rowData) }} />
                            </IconButton>
                            <IconButton aria-label="add to favorites">
                                <ChromeReaderModeIcon onClick={() => { navigate(`/complate_read/${props.rowData.id}`) }} />
                            </IconButton>
                        </> : <></>}
                    </CardActions>
                    {props.update || props.delete ?
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{ position: 'absolute', buttom: -20, right: 15, transform: 'translate(0, -70%)' }}
                            icon={<AutoFixHighIcon />}
                            direction='up'
                        >
                            <SpeedDialAction
                                icon={<AddIcon onClick={props.show} />}
                                tooltipTitle={'إضافة نسخ'}

                            />
                            <SpeedDialAction
                                icon={<EditIcon onClick={props.update} />}
                                tooltipTitle={'تعديل'}

                            />
                            <SpeedDialAction
                                icon={<PublishIcon onClick={props.AddFile} />}
                                tooltipTitle={'إضافة نسخة إلكترونية'}

                            />
                            <SpeedDialAction
                                icon={<DeleteIcon onClick={props.delete} />}
                                tooltipTitle={'حذف'}

                            />

                        </SpeedDial> : <></>
                    }
                </Container>
            </div>
        </div>
    );
}
