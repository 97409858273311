import { useState, useEffect, useRef, useCallback } from 'react';
import http from '../tools/http';

function useFetchData(routerGet, RouterSearch, RouterGetId, type) {
    const [Data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const lastItemRef = useRef(null);
    const containerRef = useRef(null);
    const SearchRef = useRef(null);
    const isFetchingRef = useRef(false);
    const timerRef = useRef(null);

    // Handle search input change with debounce
    const handleSearch = useCallback(() => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            const value = SearchRef.current.value;
            setSearchValue(value);
            localStorage.setItem("searchValue", value);
        }, 300); // Debounce delay of 300ms
    }, []);

    useEffect(() => {
        const searchElement = SearchRef.current;
        if (searchElement) {
            searchElement.addEventListener('input', handleSearch);
            return () => {
                searchElement.removeEventListener('input', handleSearch);
            };
        }
    }, [handleSearch]);

    // Fetch data or search on initial render or when searchValue changes
    useEffect(() => {
        if (SearchRef.current) {
            const savedSearchValue = localStorage.getItem("searchValue");
            if (savedSearchValue) {
                setSearchValue(savedSearchValue);
                SearchRef.current.value = savedSearchValue;
            } else {
                fetchData(0); // Start fetching from the first page
            }
        }
    }, []);

    useEffect(() => {
        setPage(0); // Reset page to 0 whenever searchValue changes
        if (searchValue === '') {
            fetchData(0);
        } else {
            search(0);
        }
    }, [searchValue]);

    // Handle infinite scrolling
    useEffect(() => {
        const handleScroll = () => {
            if (isFetchingRef.current) return;

            const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                isFetchingRef.current = true;
                if (searchValue === '') {
                    fetchData(page);
                } else {
                    search(page);
                }
            }
        };

        const scrollElement = containerRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [page, searchValue]);

    const fetchById = async (id) => {
        try {
            const requestData = { id: id };
            const response = await http.post(RouterGetId, requestData);
            return response.data;
        } catch (error) {
            console.error('Error fetching data by ID:', error.message);
            return null;
        }
    };

    const fetchData = async (pageNum) => {
        try {
            const requestData = { page: pageNum };
            const response = type === 'get'
                ? await http.get(routerGet, { params: requestData })
                : await http.post(routerGet, requestData);

            setData(prevData => pageNum === 0 ? response.data : [...prevData, ...response.data]);
            setPage(prevPage => prevPage + 1);
            isFetchingRef.current = false;
        } catch (error) {
            console.error('Error fetching data:', error.message);
            isFetchingRef.current = false;
        }
    };

    const search = async (pageNum) => {
        try {
            const requestData = {
                searchValue: searchValue,
                page: pageNum
            };
            const response = await http.post(RouterSearch, requestData);

            setData(prevData => pageNum === 0 ? response.data : [...prevData, ...response.data]);
            setPage(prevPage => prevPage + 1);
            isFetchingRef.current = false;
        } catch (error) {
            console.error('Error searching data:', error.message);
            isFetchingRef.current = false;
        }
    };

    return { Data, setData, lastItemRef, containerRef, SearchRef, fetchById, search, fetchData };
}

export default useFetchData;
